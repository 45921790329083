import jwt_decode from "jwt-decode";
import { AccessToken } from "../types/token";

const hasToken = (accessJWT?: string): boolean => {
  let encodedToken: string | null;
  if (accessJWT) encodedToken = accessJWT;
  else encodedToken = localStorage.getItem("accessJWT");
  if (encodedToken === null) return false;
  try {
    let token: AccessToken = jwt_decode(encodedToken);
    if (Date.now() >= token?.exp * 1000) {
      localStorage.removeItem("accessJWT");
      return false;
    }
  } catch (error) {
    console.error("Mauvais token");
    return false;
  }
  return true;
};

const isActivate = (): boolean => {
  let accessToken = localStorage.getItem("accessJWT");
  if (accessToken) {
    let decodedAccessToken: AccessToken = jwt_decode(accessToken);
    if (decodedAccessToken.act === true) return true;
  }
  return false;
};

const isProfessionalCreated = (): boolean => {
  let accessToken = localStorage.getItem("accessJWT");
  if (accessToken) {
    let decodedAccessToken: AccessToken = jwt_decode(accessToken);
    if (decodedAccessToken.pro) return true;
  }
  return false;
};

const redirectToThianaDPIWithTokens = (accessToken: string) => {
  window.location.href =
    process.env.REACT_APP_URL_THIANA_DPI + "atk/" + accessToken;
};

const redirectToThianaAssistantWithAccessToken = (
  accessToken: string,
  personnalWorkspaceID: string
) => {
  window.location.href =
    process.env.REACT_APP_URL_THIANA_ASSISTANT +
    "atk/" +
    accessToken +
    "/pwid/" +
    personnalWorkspaceID;
};

export {
  hasToken,
  isActivate,
  isProfessionalCreated,
  redirectToThianaDPIWithTokens,
  redirectToThianaAssistantWithAccessToken,
};
