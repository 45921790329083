import { ThemeConfig } from "antd";

export const lightTheme: ThemeConfig = {
  token: {
    fontFamily: "Nunito",
    colorPrimary: "#0D41E1",
    colorTextHeading: "#0F1026",
    colorText: "#2C2E3E",
    colorTextBase: "#2C2E3E",
    colorTextDescription: "#6C6F83",
    colorBgBase: "#F7F8FA",
    colorBgContainer: "#F0F4F9",
    colorBorder: "#D1D4E4",
  },
  components: {
    Input: {
      hoverBorderColor: "#0D41E1",
      colorBgBase: "#FFFFFF",
      colorBgContainer: "#FFFFFF",
    },
    DatePicker: {
      hoverBorderColor: "#0D41E1",
      colorBgBase: "#FFFFFF",
      colorBgContainer: "#FFFFFF",
    },
    Select: {
      colorPrimaryHover: "#0D41E1",
      colorBgContainer: "#FFFFFF",
    },
    Button: {
      defaultBg: "#FFFFFF",
      boxShadow: "none",
      dangerShadow: "none",
      primaryShadow: "none",
      boxShadowSecondary: "none",
      boxShadowTertiary: "none",
    },
  },
};

export const darkTheme: ThemeConfig = {
  token: {
    fontFamily: "Nunito",
    colorPrimary: "#0D41E1",
    colorTextHeading: "#F7F8FA",
    colorText: "#E0E3F3",
    colorIcon: "#E0E3F3",
    colorTextBase: "#E0E3F3",
    colorTextDescription: "#A2A8C2",
    colorBgBase: "#121C39",
    colorBgContainer: "#1A2539",
    colorBorder: "#343A50",
  },
  components: {
    Input: {
      hoverBorderColor: "#0D41E1",
      activeBorderColor: "#0D41E1",
    },
    Select: {
      colorPrimaryHover: "#0D41E1",
    },
    Button: {
      boxShadow: "none",
      dangerShadow: "none",
      primaryShadow: "none",
      boxShadowSecondary: "none",
      boxShadowTertiary: "none",
    },
    Tooltip: {
      colorBgSpotlight: "#000",
    },
  },
};
