import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthController from "./components/public/AuthController";
import AppContext from "./context/Context";
import { emptyUser, User } from "./types/users";
import { RedirectionController } from "./components/protected/redirection/RedirectionController";
import Protected from "./components/Protected";
import Reset from "./components/public/resetpassword/Reset";
import Logout from "./components/public/logout/Logout";
import Error from "./components/public/error/Error";
import DownloadThianaAssistant from "./components/protected/thiana-assistant/DownloadThianaAssistant";
import Activate from "./components/public/activate/Activate";
import { ConfigProvider } from "antd";
import colors from "./theme/thiana";
import Layout from "./components/layout/Layout";
import Informations from "./components/protected/informations/Informations";
import SelectAccountType from "./components/protected/select-account-type/SelectAccountType";
import { lightTheme, darkTheme } from "./theme/themes";

function App() {
  const [user, setUser] = useState<User>(emptyUser);
  const [accountType, setAccountType] = useState<string>("");

  /**
   * Set initial values for app context
   * And later in this component, we pass AppContextValue in useContext that is wrapping the whole app
   */
  const AppContextValue = {
    user,
    updateUser: setUser,
    accountType,
    updateAccountType: setAccountType,
  };

  return (
    <ConfigProvider theme={lightTheme}>
      <AppContext.Provider value={AppContextValue}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <AuthController />
                </Layout>
              }
            />
            <Route
              path="/select-account-type"
              element={
                <Protected>
                  <Layout>
                    <SelectAccountType />
                  </Layout>
                </Protected>
              }
            />
            <Route
              path="/informations"
              element={
                <Protected>
                  <Layout>
                    <Informations />
                  </Layout>
                </Protected>
              }
            />

            <Route
              path="/choose-your-app"
              element={
                <Protected>
                  <RedirectionController />
                </Protected>
              }
            />
            <Route path="/reset/:r" element={<Reset />} />
            <Route
              path="/activate/:a?"
              element={
                <Protected>
                  <Layout>
                    <Activate />
                  </Layout>
                </Protected>
              }
            />
            <Route path="/logout" element={<Logout />} />
            <Route
              path="/download-thiana-assistant"
              element={<DownloadThianaAssistant />}
            />
            <Route path="*" element={<Error />} />
          </Routes>
        </BrowserRouter>
      </AppContext.Provider>
    </ConfigProvider>
  );
}

export default App;
