import { useNavigate } from "react-router-dom";
import {
  isActivate,
  isProfessionalCreated,
  redirectToThianaAssistantWithAccessToken,
  redirectToThianaDPIWithTokens,
} from "../../../utils/security";
import { useContext, useEffect, useState } from "react";
import { Button, Flex, theme, Tooltip, Typography } from "antd";
import colors from "../../../theme/thiana";
import useScreenSize from "../../../hooks/useScreenSize";
import breakpoints from "../../../theme/antdesign";
import thiana_head from "../../../assets/logo/LOGO_HEAD.svg";
import Context from "../../../context/Context";

export function RedirectionController() {
  let navigate = useNavigate();
  const [isAssistantHovered, setIsAssistantHovered] = useState(false);
  const [isDPIHovered, setIsDPIHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const { currentScreenSize } = useScreenSize();

  const { token } = theme.useToken();

  useEffect(() => {
    if (!isActivate()) navigate("/activate");
    else if (!isProfessionalCreated()) navigate("/informations");
  }, [navigate]);

  const redirectToThianaDPI = () => {
    let accessToken = localStorage.getItem("accessJWT");
    if (accessToken) redirectToThianaDPIWithTokens(accessToken);
  };

  const redirectToThianaAssistant = () => {
    let accessToken = localStorage.getItem("accessJWT");
    let personnalWorkspaceID = localStorage.getItem("personnal_workspace_id");
    if (accessToken)
      redirectToThianaAssistantWithAccessToken(
        accessToken,
        personnalWorkspaceID as string
      );
  };

  const cardStyle: React.CSSProperties = {
    width: 384,
    display: "flex",
    gap: 8,
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
    borderRadius: 16,
    padding: 32,
    boxShadow: "0px 2px 4px -2px rgba(24, 24, 28, 0.06)",
    transition: "transform 0.2s, box-shadow 0.2s",
    backgroundColor: "white",
    border: `1px solid ${token.colorBorder}`,
  };

  const cardStyleHover: React.CSSProperties = {
    ...cardStyle,
    transform: "scale(1.05)",
    boxShadow: "0px 4px 8px -4px rgba(24, 24, 28, 0.06)",
    backgroundColor: isClicked
      ? colors.thiana.grey[500]
      : token.colorBgContainer,
  };

  const handleCardClick = (mouseClic: boolean) => {
    setIsClicked(mouseClic);
  };

  const handleAssistantCardLeave = () => {
    if (isClicked) setIsClicked(false);
    setIsAssistantHovered(false);
  };

  const handleDPICardLeave = () => {
    if (isClicked) setIsClicked(false);
    setIsDPIHovered(false);
  };

  return (
    <Flex
      align="center"
      justify="center"
      style={{ background: token.colorBgBase }}
    >
      <div
        style={{
          minHeight: "100vh",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 15,
        }}
      >
        {process.env.REACT_APP_CURRENT_ENV === "PREVIEW" ? (
          <div
            style={{
              position: "fixed",
              top: 10,
              left: 10,
              color: "red",
              fontSize: 14,
            }}
          >
            Version de test
          </div>
        ) : process.env.REACT_APP_CURRENT_ENV === "DEV" ? (
          <div
            style={{
              position: "fixed",
              top: 10,
              left: 10,
              color: "green",
              fontSize: 14,
            }}
          >
            Environnement de développement
          </div>
        ) : null}

        <Typography.Title
          level={1}
          style={{
            fontWeight: 600,
            fontSize: 36,
            margin: 0,
            textAlign: "center",
          }}
        >
          Choisissez votre application
        </Typography.Title>
        <Flex
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection:
              currentScreenSize < breakpoints.sm ? "column" : "row",
            margin: 10,
            gap: 40,
            marginBottom: 20,
          }}
        >
          {/* Thiana Assistant */}
          <Flex
            style={isAssistantHovered ? cardStyleHover : cardStyle}
            onMouseEnter={() => setIsAssistantHovered(true)}
            onMouseDown={() => handleCardClick(true)}
            onMouseUp={() => handleCardClick(false)}
            onMouseLeave={handleAssistantCardLeave}
            onClick={redirectToThianaAssistant}
            data-cy="action-to-assistant"
          >
            <Flex align="center" gap={4}>
              <img src={thiana_head} width={64} alt="Logo Thiana Doc" />
              <Typography.Text style={{ fontSize: 32, fontWeight: 600 }}>
                Thiana Assistant
              </Typography.Text>
            </Flex>

            <Typography.Paragraph style={{ textAlign: "center", fontSize: 16 }}>
              <b>Thiana Assistant :</b> Génération automatique de vos
              observations médicale à partir de langage naturel.
            </Typography.Paragraph>
          </Flex>

          {process.env.REACT_APP_CURRENT_ENV !== "PROD" ? (
            <Flex
              style={isDPIHovered ? cardStyleHover : cardStyle}
              onMouseEnter={() => setIsDPIHovered(true)}
              onMouseDown={() => handleCardClick(true)}
              onMouseUp={() => handleCardClick(false)}
              onMouseLeave={handleDPICardLeave}
              onClick={redirectToThianaDPI}
            >
              <Flex align="center" gap={4}>
                <img src={thiana_head} width={64} alt="Logo Thiana Doc" />
                <Typography.Text style={{ fontSize: 32, fontWeight: 600 }}>
                  Thiana DPI
                </Typography.Text>
              </Flex>

              <Typography.Paragraph
                style={{ textAlign: "center", fontSize: 16 }}
              >
                <b>Thiana Dossier Patient :</b> Logiciel complet de gestion de
                dossier patient complet pour médecin libéraux
              </Typography.Paragraph>
            </Flex>
          ) : (
            <Tooltip
              title="Application en cours de développement ..."
              placement="right"
            >
              <Flex style={cardStyle}>
                <Flex align="center" gap={4}>
                  <img src={thiana_head} width={64} alt="Logo Thiana Doc" />
                  <Typography.Text style={{ fontSize: 32, fontWeight: 600 }}>
                    Thiana DPI
                  </Typography.Text>
                </Flex>

                <Typography.Paragraph
                  style={{ textAlign: "center", fontSize: 16 }}
                >
                  Logiciel de gestion de dossier patient complet pour médecin
                  libéraux
                </Typography.Paragraph>
              </Flex>
            </Tooltip>
          )}
        </Flex>
        <Button
          type="text"
          size="large"
          danger
          onClick={() => navigate("/logout")}
          style={{ color: colors.thiana.grey[500] }}
        >
          Déconnexion
        </Button>
      </div>
    </Flex>
  );
}
