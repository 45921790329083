import useScreenSize from "../../hooks/useScreenSize";
import breakpoints from "../../theme/antdesign";
import colors from "../../theme/thiana";
import visualLight from "../../assets/logo/doctor-patient-conversation-light.svg";
import visualDark from "../../assets/logo/doctor-patient-conversation-dark.svg";
import ThianaLogo from "../../assets/logo/LOGO_HEAD.svg";
// import logo_head from "../../assets/logo/LOGO_HEAD.svg";
import { Flex, theme, Typography } from "antd";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPills } from "@fortawesome/free-solid-svg-icons";

interface Props {
  children: JSX.Element;
}
export default function Layout(props: Props) {
  let { currentScreenSize } = useScreenSize();
  const { token } = theme.useToken();
  return (
    <Flex
      flex={1}
      style={{
        alignItems: "start",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      {currentScreenSize <= breakpoints.md ? null : (
        <Flex
          flex={1}
          vertical
          style={{
            backgroundColor: token.colorBgContainer,
            height: "100%",
          }}
        >
          <Flex
            flex={1}
            align="center"
            vertical
            style={{ borderRight: `1px solid ${token.colorBorder}` }}
          >
            <Flex
              flex={1}
              justify="start"
              vertical
              style={{
                backgroundImage: `url(${visualLight})`,
                backgroundSize: "80%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                paddingTop: 80,
                width: "80%",
                maxWidth: "896px",
                minHeight: "1000px",
              }}
            >
              <Flex
                vertical
                justify="center"
                align="center"
                style={{
                  width: "100%",
                }}
                gap={4}
              >
                <Flex gap={4}>
                  <img src={ThianaLogo} alt="Logo" style={{ width: 120 }} />
                  <Typography.Text style={{ fontSize: 96, fontWeight: 600 }}>
                    Thiana
                  </Typography.Text>
                </Flex>
                <Typography.Text style={{ color: "black", fontSize: 16 }}>
                  L'intelligence artificielle au service des médecins.
                </Typography.Text>
              </Flex>
              {/* <Flex flex={1} align="end" vertical style={{ width: "100%" }}> */}
              {/* <Flex style={{ width: 400, padding: 16 }}>
                <Flex>
                  <img src={logo_head} style={{ height: 48 }} />{" "}
                  <Flex vertical gap={4}>
                    <Flex
                      style={{
                        border: "1px solid white",
                        padding: 8,
                        borderRadius: 8,
                      }}
                    >
                      <Typography.Text>
                        Après analyse de votre conversation, voilà les documents
                        que j'ai rédigé pour vous
                      </Typography.Text>
                    </Flex>
                    <Flex
                      style={{
                        border: "1px solid white",
                        padding: 8,
                        borderRadius: 8,
                      }}
                    >
                      <Flex>
                        <FontAwesomeIcon icon={faPills} />
                      </Flex>
                      <Typography.Text>
                        Après analyse de votre conversation, voilà les documents
                        que j'ai rédigé pour vous
                      </Typography.Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex> */}
              {/* </Flex> */}
            </Flex>
          </Flex>
        </Flex>
      )}
      <Flex
        flex={1}
        style={{ height: "100%", backgroundColor: token.colorBgBase }}
      >
        {props.children}
      </Flex>
    </Flex>
  );
}
